import React, { useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Layout from '../components/layout';
import SEO from "../components/seo";
import { isValidEmail, addAndRemoveDisabledCls, validateSubscriberData } from '../utils/utils';
import { submitFreeConsultationForm } from '../utils/campaign';

import "../styles/style-contact.css";
// init("wJVS8NI9A6FKL8obZ");

const Contact = () => {
    const seo = { metaDesc: 'Patents, Trademarks, Copyrights, Trade Secrets, Provisional Patent, Utility Patent, USPTO' };
    //
    const captchaRef = useRef(null)
    //
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const submit = async (e) => {
        e.preventDefault();
        if (name && email && phone && message) {
            // validate email
            if (!isValidEmail(email)) {
                alert('Email is invalid!!!');
                return;
            }
            const token = captchaRef.current.getValue();
            if (!token) {
                alert('Please Confirm You Are Not a Robot!');
            } else {
                // check 
                const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
                const responseJson = await response.json();
                if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {
                    // call api
                    const data = {
                        email: email,
                        name: name,
                        phone: phone,
                        subject: '[PatentPC] New message from ' + email,
                        message: message,
                        site: 'patentpc.com'
                    }
                    
                    // validate data
                    if (!validateSubscriberData(data)) {
                        return;
                    }
                    
                    // submit active campaign
                    submitFreeConsultationForm(data)

                    // send
                    fetch("https://api.trustyip.com/subcribers/add", {
                    "method": "POST",
                    "headers": {
                        "content-type": "application/json",
                        "accept": "application/json"
                    },
                    "body": JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(response => {
                        alert('Thank you for contacting us. We will feedback to you soon!!!');
                        // reset
                        setName('');
                        setEmail('');
                        setMessage('');
                        setEmailSent(true);
                    })
                    .catch(err => {
                        alert('There is something wrong. Please try again later!');
                        console.log(err);
                    });

                    // reset
                    captchaRef.current.reset();
                    //
                    addAndRemoveDisabledCls('contactBtn');
                } else {
                    alert('Please Confirm You Are Not a Robot!');
                }
            }
        } else {
            alert('Please fill in all fields.');
        } 
    }
    return (
        <Layout>
            <SEO title="Contact US"  canonical='/contact' seo={seo} />
            <div class="main-bg">
                <div class="overlay"></div>
                <img src="/assets/images/contact.jpg" alt=""/>
                <h1>Contact Us</h1>
            </div>

            <div class="containermap">
                <div class="wrapper1">
                    <div class="wrap">
                        <img src="images/solar_phone-linear.svg" alt="" />
                        <p>4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054</p>
                    </div>
                    <div class="wrap">
                        <img src="images/solar_phone-linear.svg" alt="" />
                        <p><a href="tel:8887238320">888-723-8320</a></p>
                    </div>
                    <div class="elementor-element elementor-element-f791ca8 elementor-widget elementor-widget-google_maps"
                        data-id="f791ca8" data-element_type="widget" data-widget_type="google_maps.default">
                        <div class="elementor-widget-container">

                            <div class="elementor-custom-embed">
                                <iframe loading="lazy"
                                    src="https://maps.google.com/maps?q=4701%20Patrick%20Henry%20Dr%2C%20Building%20%2316%2C%20Santa%20Clara%2C%20CA%2095054&#038;t=m&#038;z=10&#038;output=embed&#038;iwloc=near"
                                    title="4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054"
                                    aria-label="4701 Patrick Henry Dr, Building #16, Santa Clara, CA 95054"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="createform">
                    {/* <video style={{ minHeight: 275 + 'px', width: 100 + '%' }}  preload="metadata" poster="/assets/images/contact-thumb.png" controls>
                        <source src="https://wp.patentpc.com/wp-content/uploads/2023/12/3-step-increase-valuation.mp4" type="video/mp4"></source>
                    </video> */}
                    <form class="new">
                        <label>Your name</label><br/>
                        <input class="field color" type="text"  value={name} onChange={e => setName(e.target.value)}/><br/>
                        <label>Your email</label><br/>
                        <input class="field color" type="email"  value={email} onChange={e => setEmail(e.target.value)} /><br/>
                        <label>Phone no</label><br/>
                        <input class="field color" type="number" value={phone} onChange={e => setPhone(e.target.value)} /><br/>
                        <label>Your message</label><br/>
                        <textarea class="field color" cols="65" rows="4" value={message} onChange={e => setMessage(e.target.value)} ></textarea>
                        <ReCAPTCHA sitekey="6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI" ref={captchaRef} />
                        <button id="contactBtn" className='btn btn-warning' onClick={submit}>Submit</button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default Contact;
